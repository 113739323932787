import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import ProtectedRoute from "./common/components/ProtectedRoute";
import OpticianDashboard from "./components/Optician/OpticianDashboard";
import { AuthProvider, useAuth } from "./context/AuthContext";
import PricingPlans from "./components/Optician/PricingPlans";
import PaymentForm from "./components/Optician/PaymentForm";
import SponsorList from "./components/Optician/Sponsors/Sponsors";
import AddSponsor from "./components/Optician/Sponsors/AddSponsors";
import Profile from "./components/Optician/Profil/Profil";
import Support from "./features/support/pages/Support";
import MyCustomers from "./components/MyCustomers/MyCustomers";
import Vouchers from "./features/vouchers/pages/Vouchers";
import {
  ForgotPassword,
  Login,
  Register,
  RegisterUser,
  ResetPassword,
} from "./features/auth/pages";
import QRCode from "./features/qr-code/pages/QRCode";
import UseMobileApp from "./features/redirect-user/pages/UseMobileApp";
import NewIban from "./components/Optician/NewIban";
import MyInvoices from "./features/invoices/pages/MyInvoices";
import Worker from "./features/worker/pages/Worker";

function App() {
  const stripePromise = loadStripe(
    "pk_live_51PLuQiHzL8gubNCfOBaaohZ0Vwz2Rti3HVD5YSdxui9saiRuPA1T0XRfNKEfvmlxSeKBHJX3uGBcOGdd769RoChF00t2XrnVLW"
  );
  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <div className="App">
            <div>
              <Routes>
                <Route path="/" element={<DefaultRedirect />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/sign-up" element={<RegisterUser />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                // I LOVE OPTIC
                <Route path="/qr-code-2545151154" element={<QRCode creatorId={59} />} />
                //ARIA OPTIC
                <Route path="/qr-code-2548759596" element={<QRCode creatorId={193} />} />
                //LA LUNNETERIE AMIENS
                <Route path="/qr-code-2584759654" element={<QRCode creatorId={156} />} />
                //A TOUT POINT DE VUE
                <Route path="/qr-code-2574854532" element={<QRCode creatorId={186} />} />
                //OPTIKENNEDY
                <Route path="/qr-code-2698456214" element={<QRCode creatorId={231} />} />
                //OPTIC JUAN
                <Route path="/qr-code-2647584596" element={<QRCode creatorId={233} />} />
                //VISION FACTORY
                <Route path="/qr-code-2636514256" element={<QRCode creatorId={218} />} />

                <Route path="/app-mobile" element={<UseMobileApp />} />+
                <Route
                  path="/optician-dashboard"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <OpticianDashboard />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/customers"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <MyCustomers />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/invoices"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <MyInvoices />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/plan"
                  element={<ProtectedRoute element={<PricingPlans />} />}
                />
                <Route
                  path="/payment"
                  element={<ProtectedRoute element={<PaymentForm />} />}
                />
                <Route
                  path="/sponsors"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <SponsorList />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/workers"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <Worker />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/bons"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <Vouchers />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/profil"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <Profile />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/support"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOptician>
                          <Support />
                        </RequireOptician>
                      }
                    />
                  }
                />
                <Route
                  path="/optician-support"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <Support />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/sponsors/add"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <AddSponsor />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route
                  path="/change-card"
                  element={
                    <ProtectedRoute
                      element={
                        <RequireOpticianRole>
                          <NewIban />
                        </RequireOpticianRole>
                      }
                    />
                  }
                />
                <Route path="/default" element={<DefaultRedirect />} />
              </Routes>
            </div>
          </div>
        </Router>
      </Elements>
    </AuthProvider>
  );
}

const DefaultRedirect = ({ children }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (currentUser.role === "optician") {
    return <Navigate to="/optician-dashboard" />;
  }

  if (currentUser.role !== "optician") {
    return <Navigate to="/app-mobile" />;
  }

  return children;
};
const RequireOpticianRole = ({ children }) => {
  const { currentUser, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (currentUser.role !== "optician") {
    return <Navigate to="/default" />;
  }

  if (
    !currentUser.plan_level ||
    !currentUser.stripe_subscription_id ||
    !currentUser.stripe_customer_id
  ) {
    return <Navigate to="/plan" />;
  }

  return children;
};

const RequireOptician = ({ children }) => {
  const { currentUser, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (currentUser.role !== "user") {
    return <Navigate to="/default" />;
  }

  return children;
};

export default App;
