import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import authService from "../services/authService";
import { useAuth } from "../../../context/AuthContext.js";
import api from "../../../services/api.js";
import AuthForm from "../components/AuthForm";
import Logo from "../../../common/assets/blue-logo.svg";

const Register: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();
  const location = useLocation();
  const [apiError, setApiError] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const password = watch("password", "");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const signature = params.get("signature");

    api
      .get(`/auth/register?signature=${signature}`)
      .then((response) => {
        setValue("signature", signature);
      })
      .catch((error) => {
        console.error("Error verifying signature", error);
        setApiError(true);
      });
  }, [location.search, setValue]);

  const onSubmit = async (data: any) => {
    data.firstName = data.firstName.trim();
    data.lastName = data.lastName.trim();

    const urlParams = new URLSearchParams(location.search);
    const sector = urlParams.get("sector");
    const seller_type = urlParams.get("seller_type");

    data.sector = sector;
    data.seller_type = seller_type;

    try {
      const response = await authService.register(data);
      setCurrentUser(response.user);
      navigate("/default");
    } catch (error) {
      console.error("Registration failed", error);
    }
  };

  const handleAddressChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const query = e.target.value;
    setValue("address", query);
    if (query.length > 2) {
      try {
        const response = await axios.get(
          `https://api-adresse.data.gouv.fr/search/?q=${query}`
        );
        setAddressSuggestions(response.data.features);
      } catch (error) {
        console.error("Error fetching address suggestions", error);
      }
    } else {
      setAddressSuggestions([]);
    }
  };

  const handleAddressSelect = (suggestion: any) => {
    setValue("address", suggestion.properties.label);
    setAddressSuggestions([]);
  };

  const inputClass = (error: any) =>
    `w-full px-3 py-2 border rounded ${error ? "border-red-500" : ""}`;

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="w-full flex min-h-screen overflow-hidden">
        {/* Desktop version of the left section */}
        <div
          className="hidden lg:flex p-16 flex-col justify-between items-start"
          style={{
            width: "35%",
            backgroundColor: "#cadcfa",
            height: "100vh",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src={Logo}
            style={{ marginLeft: 15 }}
            alt="Logo"
            className="h-8"
          />
          <img
            src={require("../../../common/assets/signup-illu.png")}
            style={{
              marginLeft: "auto",
              position: "absolute",
              width: "90%",
              bottom: 0,
              right: 0,
            }}
            alt="Logo"
          />
        </div>

        {/* Mobile and tablet version */}
        <div
          className="flex flex-col w-full lg:pr-72 lg:w-2/3 overflow-y-auto px-6 py-8 sm:px-8 lg:px-28 lg:py-16"
          style={{
            height: "100vh",
          }}
        >
          <div className="lg:hidden mb-6 flex justify-center">
            <img src={Logo} alt="Logo" className="h-8" />
          </div>
          {apiError ? (
            <div className="w-full h-screen flex items-center">
              <p className="text-gray-700 text-center font-semibold">
                Ce lien a déjà été utilisé ou est expiré.
                <br /> Veuillez contacter Fideliz pour plus d'informations.
              </p>
            </div>
          ) : (
            <>
              <h2 className="text-3xl lg:text-4xl font-bold mb-6 lg:mb-9 text-center lg:text-left">
                Créez votre compte pro et boostez votre CA avec notre solution
                personnalisée !
              </h2>
              <p className="mb-4 lg:mb-7 text-sm text-center lg:text-left">
                * L'ensemble des champs sont obligatoires
              </p>
              <div
                className="flex justify-center items-center mb-6 lg:mb-10 py-4 lg:py-5 rounded-lg"
                style={{ backgroundColor: "#FAF9F8" }}
              >
                <p className="text-sm">
                  Déjà un compte ?{" "}
                  <a href="/login" className="font-bold underline">
                    Connectez-vous
                  </a>
                </p>
              </div>
              <AuthForm
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                errors={errors}
                fields={[
                  {
                    label: "Prénom",
                    type: "text",
                    name: "firstName",
                    validation: {
                      required: "Le prénom est requis",
                      pattern: {
                        value: /^[A-Za-zÀ-ÿ '-]+$/,
                        message: "Le prénom ne peut pas contenir de chiffres",
                      },
                    },
                  },
                  {
                    label: "Nom",
                    type: "text",
                    name: "lastName",
                    validation: {
                      required: "Le nom est requis",
                      pattern: {
                        value: /^[A-Za-zÀ-ÿ '-]+$/,
                        message: "Le nom ne peut pas contenir de chiffres",
                      },
                    },
                  },
                  {
                    label: "Nom de votre entreprise",
                    type: "text",
                    name: "opticianName",
                    validation: {
                      required: "Le nom de votre entreprise est requis",
                    },
                  },
                  {
                    label: "Email",
                    type: "email",
                    name: "email",
                    validation: {
                      required: "L'email est requis",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Adresse email invalide",
                      },
                    },
                  },
                  {
                    label: "Mot de passe",
                    type: "password",
                    name: "password",
                    validation: {
                      required: "Le mot de passe est requis",
                      pattern: {
                        value:
                          /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                        message:
                          "Le mot de passe doit comporter au moins 8 caractères, une majuscule, un chiffre et un caractère spécial",
                      },
                    },
                  },
                  {
                    label: "Confirmer le mot de passe",
                    type: "password",
                    name: "confirmPassword",
                    validation: {
                      required: "La confirmation du mot de passe est requise",
                      validate: (value: string) =>
                        value === watch("password") ||
                        "Les mots de passe ne correspondent pas",
                    },
                  },
                  {
                    label: "Adresse postale",
                    type: "text",
                    name: "address",
                    validation: { required: "L'adresse est requise" },
                    onChange: handleAddressChange,
                    showSuggestions: true,
                  },
                  {
                    label: "Téléphone personnel",
                    type: "tel",
                    name: "phone",
                    validation: {
                      required: "Le numéro de téléphone est requis",
                      pattern: {
                        value: /^0\d{9}$/,
                        message:
                          "Le numéro de téléphone doit commencer par 0 et contenir exactement 10 chiffres",
                      },
                      maxLength: {
                        value: 10,
                        message:
                          "Le numéro de téléphone doit contenir exactement 10 chiffres",
                      },
                    },
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      let value = e.target.value.replace(/\D/g, ""); // Retirer les caractères non numériques
                      if (value.length > 10) {
                        value = value.slice(0, 10); // Limiter à 10 caractères
                      }
                      setValue("phone", value); // Mettre à jour la valeur du champ
                    },
                    inputProps: {
                      maxLength: 10, // Empêcher la saisie de plus de 10 caractères
                      pattern: "[0-9]*", // Restreindre l'entrée aux chiffres uniquement
                      inputMode: "numeric", // Clavier numérique sur les mobiles
                    },
                  },
                  {
                    label: "Téléphone de l'entreprise",
                    type: "tel",
                    name: "businessPhone",
                    validation: {
                      required:
                        "Le numéro de téléphone de l'entreprise est requis",
                      pattern: {
                        value: /^0\d{9}$/,
                        message:
                          "Le numéro de téléphone doit commencer par 0 et contenir exactement 10 chiffres",
                      },
                      maxLength: {
                        value: 10,
                        message:
                          "Le numéro de téléphone doit contenir exactement 10 chiffres",
                      },
                    },
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      let value = e.target.value.replace(/\D/g, ""); // Retirer les caractères non numériques
                      if (value.length > 10) {
                        value = value.slice(0, 10); // Limiter à 10 caractères
                      }
                      setValue("businessPhone", value); // Mettre à jour la valeur du champ
                    },
                    inputProps: {
                      maxLength: 10, // Empêcher la saisie de plus de 10 caractères
                      pattern: "[0-9]*", // Restreindre l'entrée aux chiffres uniquement
                      inputMode: "numeric", // Clavier numérique sur les mobiles
                    },
                  },
                  {
                    label: "Numéro de TVA",
                    type: "text",
                    name: "vatNumber",
                    validation: {
                      required: "Le numéro de TVA est requis",
                      pattern: {
                        value: /^[A-Z0-9]{2,}$/,
                        message: "Le numéro de TVA doit être valide",
                      },
                    },
                  },
                ]}
                addressSuggestions={addressSuggestions}
                handleAddressSelect={handleAddressSelect}
                submitButtonText="S'inscrire"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
