import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";

function QRCode({ creatorId }) { 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUrlAndRedirect = async () => {
      try {
        const response = await api.get("/qr-code/generate-link", {
          params: { creator_id: creatorId },
        });
        const data = await response.data.url;
        console.log(data);
        if (data) {
          navigate(data);
        } else {
          throw new Error("URL not found");
        }
      } catch (error) {
        console.error("Failed to fetch URL:", error);
      }
    };
    fetchUrlAndRedirect();
  }, [creatorId, navigate]); 

  return <div>Loading...</div>;
}

export default QRCode;